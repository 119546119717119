a {
  text-decoration: none !important;
}

.homepage-wrapper {
  min-height: 100vh;
}

.homepage-container {
  max-width: 850px;
  margin: 0 auto;
}

.homepage-time,
.homepage-title {
  width: 100%;
  text-align: center;
}

.homepage-grid-wrapper {
  margin: 0 auto;
}

.homepage-item-container {
  width: 100%;
  overflow: hidden;
  cursor: pointer;
}

.homepage-item-container:hover {
  opacity: 0.8;
}

.homepage-item {
  margin: 0 auto;
  border-radius: 40px;
  text-align: center;
}

.homepage-item-image {
  text-align: center;
}

.homepage-item-image img {
  width: 90%;
}

.homepage-logo-container {
  text-align: center;
}

.homepage-logo-container img {
  max-height: 150px;
}

@media (max-width: 600px) { 
  .homepage-logo-container img {
    max-height: 100px;
  }
}