.page-title-breadcrumb,
.page-title {
  margin: 0;
}

.page-title-breadcrumb {
  cursor: pointer;
}

a:visited {
  color: #dfd9c6;
  background-color: transparent;
  text-decoration: none;
}