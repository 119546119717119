body,
.font--main {
  font-family: 'Dosis', sans-serif !important;
}

.font-color-dark {
  color: #938979 !important;
}

.font-color-light {
  color: #dfd9c6 !important;
}

.font-color-white {
  color: #fff !important;
}

.background-main {
  background-color: #f6f3e6;
}

.background-dark {
  background-color: #938979;
}

.background-blue {
  background-color: #8dd6e0 !important;
}

.background-green {
  background-color: #9de3c7 !important;
}

.background-green-light {
  background-color: #9de3c76b !important;
}

.padded-6y {
  padding-top: 60px;
  padding-bottom: 60px;
}

.padded-4y {
  padding-top: 40px;
  padding-bottom: 40px;
}

.padded-2y {
  padding-top: 20px;
  padding-bottom: 20px;
}

.padded-1y {
  padding-top: 20px;
  padding-bottom: 20px;
}

.padded-4x {
  padding-left: 40px;
  padding-right: 40px;
}

.padded-2x {
  padding-left: 20px;
  padding-right: 20px;
}

.padded-1x {
  padding-left: 10px;
  padding-right: 10px;
}

.main-search-wrapper,
.main-title-button-wrapper {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 20px;
  right: 0;
}

.main-title-container {
  position: relative;
}

.main-search-icon {
  padding-right: 10px;
}

.main-modal-wrapper {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.main-modal-container {
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 500px;
  background-color: #fff;
  max-height: 90vh;
  overflow: auto;
}

.main-details-close-button {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.main-button {
  background-color: #8dd6e0 !important;
  color: #fff !important;
  font-family: 'Dosis', sans-serif !important;
}

.main--flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.main--section {
  background-color: #fff;
}



/** React components style override **/

.MuiPaper-elevation1:before,
.MuiPaper-elevation1 {
  box-shadow: none !important;
  background-color: #fff !important;
}

.MuiListItem-button {
  font-family: 'Dosis', sans-serif !important;
  color: #938979 !important;
}

.MuiButton-label {
  font-family: 'Dosis', sans-serif !important;
}

.MuiRadio-colorSecondary.Mui-checked,
.MuiFormLabel-root.Mui-focused,
.MuiTab-textColorPrimary.Mui-selected {
  color: #8dd6e0 !important;
}

.MuiChip-label {
  font-family: 'Dosis', sans-serif !important;
}

.PrivateTabIndicator-colorPrimary-2 {
  background-color: #8dd6e0 !important;
}

.app-chip-item {
  margin-right: 5px;
  color: #fff !important;
  font-family: 'Dosis', sans-serif !important;
}


/** Media queries **/
@media (max-width: 600px) { 
  .main-modal-container {
    max-width: 300px;
  }
}